import {
  each,
  head,
  first,
  last,
  isNil,
  isEmpty,
  has,
  defaultTo,
} from 'lodash';
// eslint-disable-next-line import/no-cycle
import {
  parser,
  eventBus,
  timeUtility,
  getVideoData,
  createUtility,
  delay,
} from '@/utility';
import { keepAliveApi, dataApi } from '@/api';
import { httpTypes } from '@/utility/types';
import types from './mutationTypes';
import animate from '../utility/animate';

let countdownInterval = null;
let healthCheckerTimeout = null;
let defaultHealthCheckerTimeout = null;
let stopBettingTimeout;
const getVideosTime = (time, duration) => {
  const eventStart = new Date(time);
  const newDuration = duration * 1000;
  const eventEnd = new Date(eventStart.getTime() + newDuration);

  const utcNow = new Date().getTime();
  const eventNewEnd = new Date(eventEnd).getTime();
  return Math.floor((eventNewEnd - utcNow) / 1000);
};

export default {
  sendReadyMessage() {
    window.parent.postMessage('VisualizationReady', '*');

    if (window.webkit?.messageHandlers?.WebAppListener) {
      console.log('[VirtualSoccer] Sending post messege to iOS native, "WebAppListener" messageHandler');
      window.webkit.messageHandlers.WebAppListener.postMessage('VisualizationReady');
    }
  },
  setUrlParams({ commit }, payload) {
    commit(types.SET_URL_PARAMS, payload);
    if (has(payload, 'appName')) {
      window.document.title = payload.appName;
    }
  },
  setStatistics({ commit }, payload) {
    commit(types.SET_STATISTICS, payload);
  },
  setConfig({ commit }, payload) {
    commit(types.SET_CONFIG, payload);
  },
  setLoader({ commit }, payload) {
    commit(types.SET_LOADER, payload);
  },
  setEventInfo({ commit }, payload) {
    commit(types.SET_EVENT_INFO, payload);
  },
  setNextEventInfo({ commit }, payload) {
    commit(types.SET_NEXT_EVENT_INFO, payload);
  },
  setTranslations({ commit }, payload) {
    commit(types.SET_TRANSLATIONS, payload);
  },
  setOffer({ commit }, payload) {
    commit(types.SET_OFFER, payload);
  },
  setRules({ commit }, payload) {
    commit(types.SET_RULES, payload);
  },
  setStateMode({ commit }) {
    commit(types.SET_STATE_MODE, true);
    setTimeout(() => {
      commit(types.SET_STATE_MODE, false);
    }, 1000);
  },
  setStateData({ commit }, payload) {
    commit(types.SET_STATE_DATA, payload);
  },
  setBusInitState({ commit }, payload) {
    commit(types.SET_BUS_INIT_STATE, payload);
  },
  initGame({ commit }) {
    commit(types.INITIALIZE_GAME, true);
  },
  setAssetsReady({ commit }, payload) {
    commit(types.SET_ASSETS_READY, payload);
  },
  setAssetsSubsetReady({ commit }, payload) {
    commit(types.SET_ASSETS_SUBSET_READY, payload);
  },
  async startRotatingEvents({ getters, dispatch }) {
    const events = [{
      name: 'countdown',
      phaseDuration: getters.countdownPhaseDuration,
      animationDuration: getters.countdownAnimationDuration,
    },
    {
      name: 'statistics',
      phaseDuration: getters.statisticsPhaseDuration,
      animationDuration: getters.statisticsAnimationDuration,
    }];

    await delay(1950);
    if (getters.isLargeResolution) {
      dispatch('rotateEvents', { countdownEvent: getters.countdownEventData, events, index: 0 });
    } else {
      dispatch('setCurrentEvent', 'statistics');
    }
  },
  async fetchAndStoreOffer({ getters, dispatch }) {
    const { productId = undefined } = getters.config;
    const offer = await dispatch('getData', {
      route: `${process.env.VUE_APP_API_GAME_SERVICE}/offer`,
      config: { headers: { 'X-Nsft-Productid': productId } },
    });
    dispatch('setEventMarkets', offer.message.slice(0, 3));
  },
  async fetchAndStoreStatistics({ getters, dispatch },
    payload = { numOfUpcoming: 1, indexOfSelected: 0 }) {
    const tenantId = defaultTo(getters.urlParams.tenantId, getters.config.tenantId);
    const stats = await dispatch('getData', {
      route: `stats/upcoming?numOfEvents=${payload.numOfUpcoming}`,
      config: { headers: { 'X-Nsft-Seven-Tenant-Uuid': tenantId } },
    });
    dispatch('setStatistics', [stats[payload.indexOfSelected]]);
  },
  async requestHandler({ commit }, { type, payload }) {
    try {
      const response = await dataApi[type](payload);
      if (payload.mutationType && response.data) {
        commit(payload.mutationType, response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  },
  async getData({ dispatch }, payload) {
    const response = await dispatch('requestHandler', { type: httpTypes.GET_DATA, payload });
    return response;
  },
  async setKeepAliveData({ getters }) {
    const { appName } = getters.urlParams;
    await keepAliveApi.check(appName);
  },
  handleConfigProperties({ commit, getters }, payload) {
    if (!isEmpty(payload)) {
      commit(types.SET_CONFIG, payload);
      commit(types.SET_OFFER, payload.state.offer);
      commit(types.SET_STATE_DATA, payload.state.gameState);
      commit(types.SET_RULES, payload.rules);
      if (getters.rules?.jackpotEnabled.value) {
        commit(types.SET_JACKPOT, first(payload.state.activeJackpot)?.amount);
      }
    }
  },
  async handleBusMessages({ commit, dispatch, getters }, payload) {
    // Ignore messages older than 5 seconds
    // Sometimes we receive such messages after the connection has
    // Been lost and found
    if (new Date(payload.sentTime) < new Date(Date.now() - 5 * 1000)) return;
    const eventName = payload?.eventName;
    const eventData = payload?.data;
    const eventIdToday = eventData?.eventIdToday;
    const { resultsDuration } = getters;
    if (eventIdToday) commit(types.SET_EVENT_ID, eventIdToday);
    // if (countdownInterval) clearInterval(countdownInterval);
    // if (defaultHealthCheckerTimeout) clearInterval(defaultHealthCheckerTimeout);
    switch (eventName) {
      // Pusher game events
      case 'startCountdown':
        dispatch('setCountdownTime', eventData.countdown);
        dispatch('setHealthCheckerTime', eventData.countdown + 5);
        dispatch('setCountdownInterval');
        await dispatch('setShouldRotateEvents', true);
        await dispatch('handleStartCountdown', eventData);
        dispatch('setShouldDropHeaderBeforeCountdown', true);
        break;
      case 'showVideos':
        dispatch('setHealthCheckerTime', eventData.totalDuration);
        dispatch('handleShowVideos', eventData);
        break;
      case 'showResults':
        if (getters.currentEvent === 'countdown' || getters.currentEvent === 'statistics') return;
        dispatch('setHealthCheckerTime', resultsDuration);
        dispatch('handleShowResults', eventData);
        break;
      case 'stopBetting':
        dispatch('setHealthCheckerTime', eventData.duration + 1);
        await dispatch('handleStopBetting', eventData);
        break;
      case 'updateJackpot':
        dispatch('handleJackpot', eventData);
        break;
      case 'updateAudio':
        commit(types.TOGGLE_AUDIO, eventData);
        break;
      // no default
    }
  },
  async handleConfigState({ dispatch }, configService) {
    try {
      dispatch('setDefaultHealthChecker');
      const config = await configService.getConfiguration();
      dispatch('handleConfigProperties', config);
      dispatch('setEventMarkets', config.state.eventsOffer.slice(0, 3));
      dispatch('handleState', config.state.gameState);
    } catch (e) {
      dispatch('setDefaultHealthChecker');
      dispatch('sdk/setInfoMessage', `Config state Error ${e}`);
      dispatch('setLoader', true);
    }
  },
  async handleState({ commit, dispatch, getters }, payload) {
    dispatch('setStateMode');
    dispatch('setCurrentEvent', 'state');
    const currentEvent = last(payload);
    const { eventName } = currentEvent;
    const { sentTime } = currentEvent.message;
    const eventData = currentEvent?.message?.data;
    const { eventIdToday, eventStartTime } = eventData;
    if (eventIdToday) commit(types.SET_EVENT_ID, eventIdToday);
    if (defaultHealthCheckerTimeout) clearInterval(defaultHealthCheckerTimeout);
    switch (eventName) {
      case 'showVideos': {
        const upcomingEvent = getters.allEvents[0];
        // since we want to show the countdown stage,
        // we need to modify the offer object
        const constructedEventData = {
          countdown: timeUtility.getCountdownTime(upcomingEvent.timestamp),
          eventId: upcomingEvent.eventId,
          eventIdToday: upcomingEvent.eventIdToday,
          eventStartTime: upcomingEvent.timestamp,
        };
        commit(types.SET_EVENT_ID, constructedEventData.eventIdToday);
        const videosDelay = getVideosTime(eventStartTime, eventData.totalDuration);
        // start counting down until the next match
        const countdownTime = timeUtility.getCountdownTime(constructedEventData.eventStartTime);
        dispatch('setCountdownTime', countdownTime);
        dispatch('setCountdownInterval');

        dispatch('setShouldRotateEvents', !getters.isLargeResolution);
        await dispatch('setShouldDropHeaderBeforeCountdown', false);
        await dispatch('handleStartCountdown', constructedEventData);

        // skip results message, wait until the next countdown message
        dispatch('setHealthCheckerTime', videosDelay + 21);

        break;
      }
      case 'showResults': {
        const currentEventVideo = head(payload);
        const { data } = currentEventVideo.message;
        const { resultsDuration } = getters;
        dispatch('setHealthCheckerTime', resultsDuration);
        dispatch('setVideoState', data.videos);
        dispatch('handleShowResults', eventData);
        break;
      }
      case 'startCountdown': {
        const countdownTime = timeUtility.getCountdownTime(eventStartTime);
        const countdownDelay = timeUtility.getCountdownDelay(sentTime);
        dispatch('setCountdownTime', countdownTime);
        dispatch('setCountdownDelay', countdownDelay);
        dispatch('setCountdownInterval');
        dispatch('setHealthCheckerTime', countdownTime + 5);
        dispatch('handleStartCountdown', eventData);
        break;
      }
      case 'stopBetting':
        dispatch('handleStopBetting', eventData);
        break;
      default:
    }
  },
  async handleStopBetting({ commit, dispatch, getters }, payload) {
    // not in iframe
    if (getters.urlParams.messaging !== 'postmessage') setTimeout(() => dispatch('fetchAndStoreOffer'), 6000);
    // in iframe - web
    else if (!getters.stateMode) dispatch('setEventMarkets', payload.offer.slice(0, 3));

    commit(types.SET_STOP_BETTING_DURATION, payload.duration);

    if (!getters.assetsReady) return;
    stopBettingTimeout = setTimeout(() => {
      commit(types.SET_LOADER, true);
      dispatch('sdk/setInfoMessage', getters.translate('noMoreBetting'), { root: true });
    }, 1300);
  },
  handleJackpot({ getters, commit }, payload) {
    const { tenantId } = getters.config;
    each(payload, (jackpot) => {
      if (!isNil(jackpot.tenantUuid) && jackpot.tenantUuid === tenantId) {
        commit(types.SET_JACKPOT, jackpot.amount);
      }
    });
  },
  handleShowJackpotWon({ dispatch, commit, getters }, payload) {
    const { tenantId } = getters.config;
    each(payload, (jackpotWonData) => {
      if (!isNil(jackpotWonData.tenantUuid) && jackpotWonData.tenantUuid === tenantId) {
        // commit(types.SET_CURRENT_EVENT, 'jackpotwon');
        dispatch('setCurrentEvent', 'jackpotwon');
        commit(types.SET_JACKPOTWON_DATA, jackpotWonData);
      }
    });
  },
  /**
 * Rotates through a series of component metadata periodically based on specified events.
 * @param {Array<{ name: string, phaseDuration: number, animationDuration: number }>} events
 * @param {any} countdownEvent - The current countdown event
 * @param {number} index - The index of the next event we want to show
 */
  async rotateEvents({ dispatch }, { countdownEvent, events, index }) {
    let remainingTime = timeUtility.getCountdownTime(countdownEvent.eventStartTime);
    const event = events[index];
    // Even if we only have time to show the animation of a phase
    // We will still show it.
    // The added 6 is actually 1 + 5 which accounts in the
    // Duration of the stopBetting phase and an additional second that assures
    // animation completion.

    if (remainingTime > event.animationDuration + 6) {
      if (event.name === 'statistics') {
        animate.reverseAnimateCountdown();
        await delay(1200);
      }

      dispatch('setCurrentEvent', event.name);

      // Hold the component
      await delay(event.phaseDuration * 1000);

      const newIndex = (index + 1) % events.length;
      remainingTime = timeUtility.getCountdownTime(countdownEvent.eventStartTime);
      await dispatch('rotateEvents', { countdownEvent, events, index: newIndex });
    }
  },
  async handleStartCountdown({ getters, commit, dispatch }, eventData = {}) {
    if (eventData.countdown < 5) return;
    const { countdownDelay, stateMode } = getters;

    dispatch('fetchAndStoreStatistics');

    if (has(eventData, 'previousResults')) {
      commit(types.SET_PREVIOUS_RESULTS, eventData.previousResults);
    }

    dispatch('setCountdownEventData', eventData);
    if ((countdownDelay >= 0 && countdownDelay <= 16) && has(eventData, 'jackpot') && !isEmpty(eventData.jackpot)) {
      dispatch('handleShowJackpotWon', eventData.jackpot);
      if (!stateMode && getters.assetsReady) {
        await delay(500);
        commit(types.SET_LOADER, true);
        dispatch('sdk/setInfoMessage', getters.translate('superbonus'), { root: true });
      }
    } else {
      // commit(types.SET_CURRENT_EVENT, 'countdown');
      // if (!stateMode && getters.assetsReady) {
      await delay(500);

      if (getters.isLargeResolution) dispatch('setCurrentEvent', 'countdown');
      if (getters.shouldRotateEvents) {
        dispatch('startRotatingEvents');
      }
      if (getters.shouldDropHeaderBeforeCountdown) {
        commit(types.SET_LOADER, true);
        dispatch('sdk/setInfoMessage', '', { root: true });
      }
    }
    await delay(1800);
    if (getters.assetsReady) commit(types.SET_LOADER, false);
    commit(types.SET_EVENT_INFO, false);
    commit(types.SET_NEXT_EVENT_INFO, true);
  },
  handleShowVideos({ getters, commit, dispatch }, payload) {
    if (stopBettingTimeout) clearTimeout(stopBettingTimeout);

    // commit(types.SET_CURRENT_EVENT, 'video');
    dispatch('setCurrentEvent', 'video');
    commit(types.SET_VIDEO_FINISHED, false);
    dispatch('sdk/setInfoMessage', '', { root: true });
    if (getters.assetsReady) commit(types.SET_LOADER, false);
    dispatch('setVideo', payload.videos);
    dispatch('setAllVideos', payload.videos);
    commit(types.SET_EVENT_INFO, true);
    commit(types.SET_NEXT_EVENT_INFO, false);
  },
  setVideoFinished({ commit }, payload) {
    commit(types.SET_VIDEO_FINISHED, payload);
  },
  handleShowResults({ commit, dispatch, getters }, payload) {
    const { stateMode } = getters;
    // First show match/video finished results
    commit(types.SET_VIDEO_FINISHED, true);
    if (stateMode) {
      dispatch('setResults', payload);
    } else {
      setTimeout(() => {
        dispatch('setResults', payload);
      }, 5000);
    }
  },
  setResults({ commit, dispatch, getters }, payload) {
    if (
      getters.currentEvent === 'countdown'
      || getters.currentEvent === 'statistics'
      || getters.currentEvent === 'jackpotwon'
    ) return;
    // commit(types.SET_CURRENT_EVENT, 'results');
    dispatch('setCurrentEvent', 'results');
    if (getters.assetsReady) {
      commit(types.SET_LOADER, true);
      dispatch('sdk/setInfoMessage', getters.translate('results'), { root: true });
    }
    setTimeout(() => {
      if (getters.assetsReady) commit(types.SET_LOADER, false);
      commit(types.SET_RESULTS, payload);
      commit(types.SET_EVENT_INFO, true);
      commit(types.SET_NEXT_EVENT_INFO, false);
    }, 2000);
  },
  setJackpotWonFinished({ getters, commit, dispatch }, payload) {
    commit(types.SET_JACKPOTWON_FINISHED, payload);
    setTimeout(() => {
      if (getters.assetsReady) {
        commit(types.SET_LOADER, true);
        dispatch('sdk/setInfoMessage', '');
        dispatch('startRotatingEvents');
        // commit(types.SET_CURRENT_EVENT, 'countdown');
        setTimeout(() => {
          commit(types.SET_LOADER, false);
        }, 1000);
      }
    }, 1000);
  },
  setShouldDropHeaderBeforeCountdown({ commit }, payload) {
    commit(types.SET_SHOULD_DROP_HEADER_BEFORE_COUNTDOWN, payload);
  },
  setShouldRotateEvents({ commit }, payload) {
    commit(types.SET_SHOULD_ROTATE_EVENTS, payload);
  },
  setCountdownTime({ commit }, payload) {
    const countdownTime = !isNil(payload) && payload >= 0 ? payload : 0;

    commit(types.SET_COUNTDOWN_TIME, countdownTime);
  },
  setCountdownEventData({ commit }, payload) {
    commit(types.SET_COUNTDOWN_EVENT_DATA, payload);
  },
  setCountdownDelay({ commit }, payload) {
    commit(types.SET_COUNTDOWN_DELAY, payload);
  },
  setCountdownInterval({ dispatch, getters }) {
    if (countdownInterval) clearInterval(countdownInterval);
    countdownInterval = setInterval(() => {
      let time = getters.countdownTime - 1;

      if (countdownInterval && getters.countdownTime <= 0) {
        clearInterval(countdownInterval);
        time = 0;
        dispatch('setCountdownDelay', time);
      }

      dispatch('setCountdownTime', time);
    }, 1000);
  },
  setCurrentEvent({ commit, getters }, payload) {
    const { currentEvent } = getters;
    if (!isNil(payload) && (payload !== currentEvent)) {
      commit(types.SET_CURRENT_EVENT, payload);
    }
  },
  setEventMarkets({ getters, commit }, payload) {
    const eventMarketOutcomes = parser.countdownParser(getters.offer, payload);
    commit(types.SET_EVENT_MARKET_OUTCOMES, eventMarketOutcomes);
  },
  setIsHalfTime({ commit }, payload) {
    commit(types.SET_IS_HALF_TIME, payload);
  },
  setWindowWidth({ commit }, payload) {
    commit(types.SET_WINDOW_WIDTH, payload);
  },
  setHasChartAnimationEnded({ commit }, payload) {
    commit(types.SET_HAS_CHART_ANIMATION_ENDED, payload);
  },
  setVideoState({ commit }, payload) {
    const goalList = {
      home: [],
      away: [],
    };
    commit(types.RESET_RESULT);
    each(payload, (video, index) => {
      if (video.actionType === 'GOAL') {
        const videoData = getVideoData(payload, video, index, goalList);
        commit(types.SET_VIDEO_FINAL_RESULT, {
          home: videoData.homeResult,
          away: videoData.awayResult,
        });
        commit(types.SET_VIDEO_GOAL_LIST, videoData.goalList);
      }
    });
  },
  setVideo(store, videos) {
    let duration = 0;
    const goalList = {
      home: [],
      away: [],
    };
    store.commit(types.RESET_RESULT);

    const {
      handleHalfPart, splitHalfParts, handleHalfTime, setFinalResultsAndGoalList,
    } = createUtility(store, types, {
      utility: { getVideoData },
    });
    const { firstHalfVideos, secondHalfVideos } = splitHalfParts(videos);

    setFinalResultsAndGoalList(videos, goalList);
    // New duration -> first half duration
    duration = handleHalfPart(firstHalfVideos, duration, goalList);

    // First half duration + halfTime duration
    duration = handleHalfTime(duration);

    handleHalfPart(secondHalfVideos, duration, goalList);
  },
  setAllVideos({ commit }, payload) {
    commit(types.SET_ALL_VIDEOS, payload);
  },
  setDisplaySettings({ commit }, payload) {
    commit(types.SET_DISPLAY_SETTINGS, payload);
  },
  setDesktopAppDetails({ commit }) {
    commit(types.SET_DESKTOP_APP_DETAILS, {
      isDesktopApp: process.env.VUE_APP_DESKTOP_BUILD === 'true',
      desktopSystem: process.env.VUE_APP_DESKTOP_SYSTEM,
    });
  },
  setJackpotActivity({ commit }, payload) {
    commit(types.SET_JACKPOT_ACTIVITY, payload);
  },
  updateOnlineStatus({ commit, state }) {
    const isOnline = navigator.onLine;
    if (isOnline) {
      commit(types.RESET_CONNECTION_LOST_TIME);
    } else if (isNil(state.connectionLostTimestamp)) {
      commit(types.SET_CONNECTION_LOST_TIME);
    }
    commit(types.SET_ONLINE_STATUS, isOnline);
  },
  setActiveGame({ commit }, payload) {
    commit(types.SET_ACTIVE_GAME, payload);
  },
  setHealthChecker({ commit, dispatch, getters }) {
    commit(types.SET_HEALTH_CHECKER, true);

    clearTimeout(healthCheckerTimeout);
    healthCheckerTimeout = setTimeout(() => {
      if (getters.isOnline) {
        dispatch('sdk/setInfoMessage', getters.translate('problemWithGame'), { root: true });
        commit(types.SET_LOADER, true);
        commit(types.SET_HEALTH_CHECKER, false);
        commit(types.SET_GAME_RESPONSIVENESS, false);
      }
    }, getters.healthCheckerTime * 1000);
  },
  setDefaultHealthChecker({ commit, dispatch, getters }) {
    if (defaultHealthCheckerTimeout) clearInterval(defaultHealthCheckerTimeout);
    defaultHealthCheckerTimeout = setInterval(() => {
      if (!getters.isConnectionAlive && getters.isOnline) {
        dispatch('sdk/setInfoMessage', getters.translate('problemWithGame'), { root: true });
        commit(types.SET_LOADER, true);
        commit(types.SET_HEALTH_CHECKER, false);
        commit(types.SET_GAME_RESPONSIVENESS, false);
        eventBus.$emit('getConfigState');
      }
    }, getters.defaultHealthCheckerTime * 1000);
  },
  setHealthCheckerTime({ commit, dispatch }, payload) {
    if (!isNil(payload) && payload >= 0) {
      commit(types.SET_HEALTH_CHECKER_TIME, payload + 5);
      dispatch('setHealthChecker');
    }
  },
  setGameResponsiveness({ commit }, payload) {
    commit(types.SET_GAME_RESPONSIVENESS, payload);
  },
};
